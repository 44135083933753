import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FallbackImg from '../components/FallbackImg';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import { handleQuoteButtonClick, handleMailingListButtonClick } from '../utils/button';
import appendQueryString from '../utils/query';

const Product = ({ data }) => {
  const {
    title, introduction, path, SEODescription, productImage,
  } = data.productData.frontmatter;
  const { html } = data.productData;
  const quoteButtonIds = ['QuoteButtonBottom'];
  // TODO differentiate between which button was clicked

  useEffect(() => {
    /**
    * When deploying on Amplify/Netlify with query parameters, the button does not
    * retain the query parameters. So we resort to a bit of a hack.
    */
    quoteButtonIds.forEach((value, _index, _array) => {
      const currentButton = document.getElementById(value);
      currentButton.href = appendQueryString(currentButton.href);
    });
  }, []);

  return (
    <Layout bodyClass="page-product">
      <SEO
        title={title}
        description={SEODescription}
        imageUrl={data.seoImage.frontmatter.productImage.childImageSharp.fixed.src}
        path={path} />
      <Container className="pt-4">
        <Row>
          <Col>
            <FallbackImg image={productImage} />
          </Col>
          <Col className="pt-4">
            <h1 className="text-center">{title}</h1>
            <br />
            {introduction}
          </Col>
        </Row>
      </Container>
      <Container className="pt-4 pt-md-4 product">
        <Row className="content" dangerouslySetInnerHTML={{ __html: html }} />
        <Row className="center mb-5 text-center">
          <h3>
            Are you ready to find the right solar solution for you?
            <br />
            EcoEnergy can instantly configure a solution
            with the
            {` ${title}`}
            .
          </h3>
        </Row>
        <Row className="center mb-2">
          <Button id="QuoteButtonBottom" href="https://quote.ecoenergy.global/" variant="success" size="lg" className="center" block onClick={handleQuoteButtonClick}>
            Get a Quote in 5 Minutes
          </Button>
        </Row>
        <Row className="center mb-5">
          <Button id="MailingListButtonBottom" href="http://eepurl.com/hiAsVX" variant="warning" size="lg" className="center" block onClick={handleMailingListButtonClick}>
            Subscribe to Our Newsletter and Learn About Solar
          </Button>
        </Row>
      </Container>
    </Layout>
  );
};

/* TODO it would be really nice to crop images (e.g. testimonials)
  based on settings in the JSON file
*/
export const query = graphql`
  query($id: String!) {
    productData: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        introduction
        path
        SEODescription
        productImage {
          childImageSharp {
            fixed(width: 600) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
          extension
          publicURL
        }
      }
      html
    }
    seoImage: markdownRemark(id: { eq: $id }) {
      frontmatter {
        productImage {
          childImageSharp {
            fixed(width: 600) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
          extension
          publicURL
        }
      }
    }
  }
`;

export default Product;
